export const BeaconStatus = Object.freeze({
  BEACON_STATUS_DRAFT: 0,
  BEACON_STATUS_IN_REVIEW: 1,
  BEACON_STATUS_PUBLISHED: 2,
  BEACON_STATUS_REJECTED: 3,
  BEACON_STATUS_COMPLETED: 4,
  BEACON_STATUS_CANCELLED: 5,
});

export const BeaconAssetStatus = Object.freeze({
  BEACON_ASSETS_ACTIVITY_INITIATED: 0,
  BEACON_ASSETS_ACTIVITY_RELEASED: 1,
  BEACON_ASSETS_ACTIVITY_ACCEPTED: 2,
  BEACON_ASSETS_ACTIVITY_REJECTED: 3,
});

export const CollaborationStatus = Object.freeze({
  PENDING: 1,
  ACCEPTED: 2,
  COMPLETED: 3,
  REJECTED: 4,
  CANCELED: 5,
});

export const IncidentReportStatus = Object.freeze({
  INCIDENT_REPORT_STATUS_DRAFT: 0,
  INCIDENT_REPORT_STATUS_PUBLISHED: 1,
});

export const OrgStatus = Object.freeze({
  SAVED: "saved",
  CREATED: "created",
  BLOCKED: "blocked",
});

export const OrgTypes = Object.freeze({
  SMB: "SMB",
  GOV: "GOV",
  NP: "NP",
});

export const OrgPaymentPlans = Object.freeze({
  FREE: "FREE",
  PREMIUM: "PREMIUM",
});
