import "./SubscribeButton.scss";

import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useSubscribeToOrg, useUnsubscribeToOrg } from "../api/organizations";
import useAuth from "../hooks/useAuth";
import useNotification from "../hooks/useNotification";
import Button from "./Button";

function SubscribeButton({ org, className }) {
  const queryClient = useQueryClient();
  const subscribeToOrgMutation = useSubscribeToOrg();
  const unsubscribeToOrgMutation = useUnsubscribeToOrg();
  const { notify } = useNotification();
  const { auth } = useAuth();

  const subscribe = useCallback(
    (org) => {
      subscribeToOrgMutation.mutate(org?.slug, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["organizations"] });
          notify("success", `You are now subscribed to ${org.name}`);
        },
        onError: (error) => {
          notify("error", "Something went wrong, please try again later.");
        },
      });
    },
    [notify, queryClient, subscribeToOrgMutation]
  );

  const unsubscribe = useCallback(
    (org) => {
      unsubscribeToOrgMutation.mutate(org?.slug, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["organizations"] });
          notify("success", `You are now unsubscribed from ${org.name}`);
        },
        onError: (error) => {
          notify("error", "Something went wrong, please try again later.");
        },
      });
    },
    [notify, queryClient, unsubscribeToOrgMutation]
  );

  const isMyOrg = org?.slug === auth?.user?.organization?.slug;
  const label = org.is_subscribed ? "Subscribed" : "Subscribe";
  return (
    <>
      {org && !isMyOrg && (
        <Button
          title={label}
          variant="primary"
          loading={subscribeToOrgMutation.isLoading}
          className={`subscribe-btn ${className ?? ""} ${
            org.is_subscribed ? "checked subscribed" : "unchecked"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            if (!org.is_subscribed) subscribe(org);
            else unsubscribe(org);
          }}>
          <span>{label}</span>
        </Button>
      )}
    </>
  );
}

export default SubscribeButton;
