import "./BeaconForm.scss";

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "../components/forms/Select";
import Button from "../components/Button";
import TextInput from "../components/forms/TextInput";
import { states } from "../data/states";
import TextArea from "../components/forms/TextArea";
import useForm from "../hooks/useForm";
import { useNavigate } from "react-router";
import Panel from "../components/Panel";
import ImageUploader from "../components/ImageUploader";
import useNotification from "../hooks/useNotification";
import OrgHeader from "../components/navigation/OrgHeader";
import {
  useEditBeacon,
  useFetchAssetCategories,
  usePostBeacon,
  usePublishBeacon,
} from "../api/beacons";
import useAuth from "../hooks/useAuth";
import { useUploadFiles } from "../api/files";

const ASSET_IMAGE_MAX_WIDTH = 700,
  ASSET_IMAGE_MAX_HEIGHT = 650;

function BeaconForm({ beacon, updateMode }) {
  const [savingAsDraft, setSavingAsDraft] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { notify } = useNotification();
  const uploadFiles = useUploadFiles();
  const { data: assetCategoriesData } = useFetchAssetCategories();
  const categories = assetCategoriesData?.result?.map((category) => ({
    value: category.id,
    label: category.name,
  }));
  const postBeaconMutation = usePostBeacon();
  const editBeaconMutation = useEditBeacon();
  const publishBeaconMutation = usePublishBeacon();
  const connectedUser = auth?.user;

  /**
   *  Publish beacon on form submit, check if updateMode enabled and makes sure it's using the correct beacon id for updates
   */
  const submitForm = async (e) => {
    try {
      setPublishing(true);
      const data = await getBeaconData({
        form: e.currentTarget,
        status: "PUBLISHED",
      });

      let beaconId;
      if (updateMode) {
        await editBeaconMutation.mutateAsync({ id: beacon.slug, data });
        beaconId = beacon.slug;
      } else {
        const newBeacon = await postBeaconMutation.mutateAsync(data);
        beaconId = newBeacon?.data?.result?.slug;
      }
      await publishBeaconMutation.mutateAsync(beaconId);
      notify(
        "success",
        "Your community request has been published successfully"
      );
      navigate(`/beacons/${beaconId}`);
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      notify("error", errMessage);
    } finally {
      setPublishing(false);
    }
  };

  const saveAsDraft = async (e) => {
    try {
      setSavingAsDraft(true);
      const data = await getBeaconData({
        form: e.target.form,
        status: "DRAFT",
      });
      if (updateMode) {
        await editBeaconMutation.mutateAsync({ id: beacon.slug, data });
        notify("success", "Your community request draft has been updated");
      } else {
        await postBeaconMutation.mutateAsync(data);
        notify("success", "Your community request has been saved as a draft");
      }
      navigate("/beacons/drafts");
    } catch (error) {
      notify("error", "We couldn't save your draft, please try again later.");
    } finally {
      setSavingAsDraft(false);
    }
  };

  const uploadBeaconAssets = async () => {
    let filesData = [],
      toUpload = [];
    try {
      for (const image of images) {
        if (!image) continue;
        if (image instanceof File) {
          toUpload.push(image);
        } else {
          filesData.push(image);
        }
      }

      if (toUpload) {
        let newFiles = await uploadFiles(toUpload);
        filesData = filesData.concat(newFiles);
      }
    } catch (error) {
      notify("error", "Error uploading assets");
    }
    return filesData;
  };
  //Format FormData to match API request spec
  const getBeaconData = async ({ form, status }) => {
    try {
      let beaconData = new FormData(form);
      beaconData.append("status", status === "DRAFT" ? 0 : 1);
      assetItems
        .filter((elt) => elt.category_id)
        .forEach((elt, index) => {
          beaconData.append(`assets[${index}][category_id]`, elt.category_id);
          beaconData.append(`assets[${index}][weight]`, elt.weight);
          beaconData.append(`assets[${index}][item]`, elt.item);
          beaconData.append(`assets[${index}][quantity]`, elt.quantity);
        });

      let filesData = await uploadBeaconAssets();
      filesData.forEach((file, index) => {
        beaconData.append(`assets[0][files_ids][${index}]`, file.id);
      });
      return beaconData;
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      notify("error", errMessage);
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, validated } =
    useForm({ ...beacon }, (event) => {
      submitForm(event);
    });

  const [assetUploadErrors] = useState([null, null, null]);

  const [assetItems, setAssetItems] = useState([
    beacon?.asset_packages[0] ?? {
      id: -1,
      item: "",
      quantity: 0,
      weight: 0,
      category_id: "",
    },
  ]);

  const [images, setImages] = useState([null, null, null]);

  const handleItems = (e, id) => {
    setAssetItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  const colWidth = 10; // used to experiment with bootstrap col width for forms, get rid of this when the ideal width is found

  /* auto-refill for testing */
  useEffect(() => {
    // if (!beacon)
    //   setFormData({
    //     title: "Beacon Title",
    //     pickup_address: "600 1st Ave",
    //     pickup_city: "Seattle",
    //     pickup_state: "WA",
    //     pickup_postal_code: 15552,
    //     dropoff_address: "Dropoff Address",
    //     dropoff_city: "Dropoff City",
    //     dropoff_state: "CA",
    //     dropoff_postal_code: 65544,
    //     instructions: "Instructions here",
    //   });

    if (updateMode) {
      const files = beacon?.asset_packages[0]?.files;
      files?.forEach((file, index) => {
        setImages((images) =>
          images.map((img, idx) => {
            return idx === index ? file : img;
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beacon, setFormData, updateMode]);

  return (
    <>
      <OrgHeader />
      <h1 className="mb-0 pb-0">
        {updateMode
          ? "Edit Community Request Draft"
          : "Create Community Request"}
      </h1>
      <p>
        A Community Request is an urgent request to the community from Nonprofit
        organizations seeking aid from the local community. By reaching out to
        local small businesses and other organizations directly we can create a
        safety net for community during unanticipated events. - an example of
        items could include masks, gloves, food and water, bedding for shelters,
        personal protective equipment or soap.
      </p>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="beacon">
        <Row>
          <Col md={colWidth}>
            <TextInput
              id="title"
              label="*Title"
              placeholder="Title"
              name="title"
              onChange={handleChange}
              value={formData.title}
              required
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={colWidth}>
            <Panel title="Creator" collapsible>
              <div>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="name"
                      label="Organization Name"
                      name="name"
                      onChange={handleChange}
                      value={connectedUser.organization?.name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Organization ID"
                      id="id"
                      name="id"
                      onChange={handleChange}
                      value={connectedUser.organization?.slug}
                      validationMessage="ID has to be alphanumeric with no spaces"
                      pattern="[a-zA-Z0-9]+"
                      required
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="first_name"
                      label="First Name"
                      name="first_name"
                      onChange={handleChange}
                      value={connectedUser.first_name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      onChange={handleChange}
                      value={connectedUser.last_name}
                      required
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="email"
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      value={connectedUser.email}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      value={connectedUser.phone}
                      required
                      readOnly
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <Panel title="Location Details" collapsible>
              <div>
                <TextInput
                  id="pickupAddress"
                  label="Pick Up Address"
                  name="pickup_address"
                  onChange={handleChange}
                  value={formData.pickup_address}
                />
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="pickupAddressCity"
                      label="City"
                      name="pickup_city"
                      onChange={handleChange}
                      value={formData.pickup_city}
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      label="State"
                      options={states}
                      name="pickup_state"
                      onChange={handleChange}
                      value={formData.pickup_state || ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      label="Zipcode"
                      name="pickup_postal_code"
                      onChange={handleChange}
                      value={formData.pickup_postal_code}
                    />
                  </Col>
                  <Col md={6}></Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <TextInput
                      id="DropOffAddress"
                      label="Drop Off Address"
                      name="dropoff_address"
                      onChange={handleChange}
                      value={formData.dropoff_address}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="DropOffAddressCity"
                      label="City"
                      name="dropoff_city"
                      onChange={handleChange}
                      value={formData.dropoff_city}
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      label="State"
                      options={states}
                      name="dropoff_state"
                      onChange={handleChange}
                      value={formData.dropoff_state || ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      label="Zipcode"
                      name="dropoff_postal_code"
                      onChange={handleChange}
                      value={formData.dropoff_postal_code}
                    />
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <Panel title="Asset Type">
              {assetItems.map((curItem, id) => (
                <div key={id}>
                  <Row>
                    <Col md={6}>
                      <Select
                        label="*Category"
                        name="category_id"
                        options={categories ?? []}
                        onChange={(e) => handleItems(e, curItem.id)}
                        value={curItem.category_id || ""}
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput
                        label="Weight"
                        name="weight"
                        type="number"
                        step="any"
                        placeholder="lbs"
                        onChange={(e) => handleItems(e, curItem.id)}
                        value={curItem.weight}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextInput
                        label="items(s)"
                        name="item"
                        onChange={(e) => handleItems(e, curItem.id)}
                        value={curItem.item}
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput
                        label="Quantity"
                        name="quantity"
                        type="number"
                        step="any"
                        onChange={(e) => handleItems(e, curItem.id)}
                        value={curItem.quantity}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <Panel title="Asset Images">
              <div>
                <p>
                  Maximum image size {ASSET_IMAGE_MAX_WIDTH}x
                  {ASSET_IMAGE_MAX_HEIGHT}
                </p>

                <Row className="pt-4">
                  {[...Array(3)].map((elt, index) => (
                    <Col
                      md={4}
                      xs={6}
                      key={index}
                      className={
                        assetUploadErrors[index]
                          ? "error text-center"
                          : "text-center"
                      }>
                      <ImageUploader
                        name={`image${index}`}
                        imageIndex={index}
                        setImages={setImages}
                        prefilledImage={images[index]}
                        maxWidth={ASSET_IMAGE_MAX_WIDTH}
                        maxHeight={ASSET_IMAGE_MAX_HEIGHT}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <Panel title="Instructions">
              <div>
                <TextArea
                  name="instructions"
                  onChange={handleChange}
                  value={formData.instructions}
                />
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col
            md={colWidth}
            className="d-flex align-content-end flex-row-reverse my-3">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              className="ms-2"
              disabled={
                !formData.title ||
                postBeaconMutation.isLoading ||
                editBeaconMutation.isLoading
              }
              loading={publishing}>
              Publish
            </Button>
            {/* {
              <Button
                type="button"
                size="lg"
                variant="secondary"
                className="ms-2"
                onClick={(e) => saveAsDraft(e)}
                disabled={
                  !formData.title ||
                  postBeaconMutation.isLoading ||
                  editBeaconMutation.isLoading
                }
                loading={savingAsDraft}>
                {updateMode ? "Update draft" : "Save as draft"}
              </Button>
            } */}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default BeaconForm;
