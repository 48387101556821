import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import OrgBadge from "../../components/OrgBadge";
import OrgHeader from "../../components/navigation/OrgHeader";
import DataGrid from "../../components/DataGrid";
import {
  useApproveCollaborationRequest,
  useFetchCollaborationsRequests,
  useRejectCollaborationRequest,
} from "../../api/collaborations";
import { useQueryClient } from "@tanstack/react-query";
import { CollaborationStatus } from "../../constants";

function Collaborations() {
  const queryClient = useQueryClient();
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data: collaborationsList } =
    useFetchCollaborationsRequests(pageIndex);
  const rejectCollborationMutation = useRejectCollaborationRequest();
  const approveCollborationMutation = useApproveCollaborationRequest();
  const navigate = useNavigate();

  const rejectCollaboration = useCallback(
    (slug) => {
      rejectCollborationMutation.mutate(slug, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["collaborations"],
          });
        },
      });
    },
    [queryClient, rejectCollborationMutation]
  );

  const approveCollaboration = useCallback(
    (slug) => {
      approveCollborationMutation.mutate(slug, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["collaborations"],
          });
        },
      });
    },
    [approveCollborationMutation, queryClient]
  );
  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original.organization} mini />
          </>
        ),
      },
      { header: "Community Request ID", accessorKey: "beacon.slug" },
      {
        header: "Address",
        accessorKey: "beacon.pickup_complete_address",
        width: 150,
      },
      { header: "Date & Time", accessorKey: "beacon.created_at" },
      {
        header: "Status",
        accessorKey: "rowid",
        cell: ({ cell }) => {
          const rowData = cell.row.original;
          if (rowData.status === CollaborationStatus.REJECTED)
            return "Declined";
          if (rowData.status === CollaborationStatus.ACCEPTED)
            return "Accepted";
          return (
            <div className="d-flex gap-4 align-items-center">
              <Button
                title="Decline"
                variant="outline-danger"
                loading={rejectCollborationMutation.isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  rejectCollaboration(rowData.slug);
                }}>
                Decline
              </Button>

              <Button
                title="Approve"
                variant="success"
                loading={approveCollborationMutation.isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  approveCollaboration(rowData.slug);
                }}>
                Accept
              </Button>
            </div>
          );
        },
      },
    ],
    [
      approveCollaboration,
      approveCollborationMutation.isLoading,
      rejectCollaboration,
      rejectCollborationMutation.isLoading,
    ]
  );

  return (
    <>
      <OrgHeader />
      <DataGrid
        title="Invitations to Collaborate"
        columns={columns}
        onRowClick={(e, row) => navigate(`/beacons/${row.beacon.slug}`)}
        isLoading={isLoading}
        data={collaborationsList?.data ?? []}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={collaborationsList?.last_page}
      />
    </>
  );
}

export default Collaborations;
