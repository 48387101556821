import "./BeaconDetails.scss";

import React, { useEffect, useState } from "react";
import { Col, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";

import chatBubbles from "../../assets/images/icons/multi_bubble.png";

import BeaconAssetImages from "../../components/beacon/BeaconAssetImages";
import Button from "../../components/Button";
import StepsProgressBar from "../../components/beacon/StepsProgessBar";
import ReleaseAssetsModal from "../../components/modals/ReleaseAssetsModal";
import useNotification from "../../hooks/useNotification";
import AcceptAssetsModal from "../../components/modals/AcceptAssetsModal";
import CompleteCollaborationModal from "../../components/modals/CompleteCollaborationModal";
import MapContainer from "../../components/MapContainer";
import OrgHeader from "../../components/navigation/OrgHeader";
import { getAssetPublicUrl, isCollaborator, isCreator } from "../../utils";
import ChatPanel from "../../components/ChatPanel";
import useAuth from "../../hooks/useAuth";
import {
  useAcceptAssets,
  useCancelBeacon,
  useCompleteBeacon,
  useRejectAssets,
  useReleaseAssets,
} from "../../api/beacons";
import BeaconActionButton from "../../components/beacon/BeaconActionButton";
import { CollaborationStatus } from "../../constants";
import RejectAssetsModal from "../../components/modals/RejectAssetsModal";
import { Link } from "react-router-dom";
import CancelBeaconOrCollaboration from "../../components/modals/CancelBeaconOrCollaboration";
import { useCancelCollaboration } from "../../api/collaborations";

function BeaconDetails({ beaconData }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { notify } = useNotification();
  const releaseAssetsMutation = useReleaseAssets();
  const acceptAssetsMutation = useAcceptAssets();
  const completeBeaconMutation = useCompleteBeacon();
  const rejectAssetsMutation = useRejectAssets();
  const cancelCollaborationMutation = useCancelCollaboration();
  const cancelBeaconMutation = useCancelBeacon();

  const asset = beaconData?.asset_packages[0];

  const [currentStep, setCurrentStep] = useState(
    beaconData?.collaboration?.asset_package_status
  );
  const [showReleaseAssetsModal, setShowReleaseAssetsModal] = useState(false);
  const [showAcceptAssetsModal, setShowAcceptAssetsModal] = useState(false);
  const [showCompleteCollaborationModal, setShowCompleteCollaborationModal] =
    useState(false);
  const [showRejectAssetsModal, setShowRejectAssetsModal] = useState(false);
  const [
    showCancelBeaconOrCollaborationModal,
    setShowCancelBeaconOrCollaborationModal,
  ] = useState(false);

  const [beaconStatus, setBeaconStatus] = useState("ASSETS_READY");

  // TODO: ACL needs to be improved, this is a temporary fix
  useEffect(() => {
    if (
      !isCollaborator(beaconData, auth?.user) &&
      !isCreator(beaconData, auth?.user)
    ) {
      navigate("/dashboard");
    }
  }, [auth?.user, beaconData, navigate]);

  useEffect(() => {
    const currentBeaconStatus = beaconData?.collaboration?.status;
    if (currentBeaconStatus === CollaborationStatus.COMPLETED) {
      setCurrentStep(CollaborationStatus.COMPLETED);
    } else {
      setCurrentStep(beaconData?.collaboration?.asset_package_status);
    }
  }, [
    beaconData?.collaboration?.asset_package_status,
    beaconData?.collaboration?.status,
  ]);

  const releaseAssets = async () => {
    try {
      setShowReleaseAssetsModal(false);
      const { data } = await releaseAssetsMutation.mutateAsync(
        beaconData?.slug
      );
      notify("success", "Assets released successfully");
      setBeaconStatus("ASSETS_RELEASED");
      setCurrentStep(data?.result?.collaboration?.asset_package_status);
    } catch (error) {}
  };

  const acceptAssets = async () => {
    setShowAcceptAssetsModal(false);
    const { data } = await acceptAssetsMutation.mutateAsync(beaconData?.slug);
    notify("success", "Assets accepted successfully");
    setBeaconStatus("ASSETS_ACCEPTED");
    setCurrentStep(data?.result?.collaboration?.asset_package_status);
  };

  const completeBeacon = async () => {
    setShowCompleteCollaborationModal(false);
    const { data } = await completeBeaconMutation.mutateAsync(beaconData?.slug);
    notify("success", "Collaboration completed successfully.");
    setBeaconStatus("COLLABORATION_COMPLETED");
    setCurrentStep(data?.result?.collaboration?.status);
  };

  const cancelCollaboration = async () => {
    try {
      const { data } = await cancelCollaborationMutation.mutateAsync(
        beaconData?.collaboration?.slug
      );
      setBeaconStatus("COLLABORATION_CANCELED");
      notify("success", "Collaboration Canceled Successfully.");
      setShowCancelBeaconOrCollaborationModal(false);
      setCurrentStep(data?.result?.collaboration?.status);
    } catch (error) {}
  };
  const cancelBeacon = async () => {
    try {
      const { data } = await cancelBeaconMutation.mutateAsync(beaconData?.slug);
      setBeaconStatus("BEACON_CANCELED");
      setShowCancelBeaconOrCollaborationModal(false);
      notify("success", "Community Request Canceled Successfully.");
      setCurrentStep(data?.result?.collaboration?.status);
      navigate("/dashboard");
    } catch (error) {}
  };

  const rejectAssets = async () => {
    try {
      const { data } = await rejectAssetsMutation.mutateAsync(beaconData?.slug);
      setBeaconStatus("ASSETS_REJECTED");
      notify("success", "Assets rejected.");
      setShowRejectAssetsModal(false);
      setCurrentStep(data?.result?.collaboration?.status);
    } catch (error) {}
  };

  /*
    TODO: remove this when the API is updated to return the correct public URL
  */
  const assetImages = beaconData?.asset_packages[0]?.files?.map((file) => ({
    url: getAssetPublicUrl(file),
  }));

  return (
    <>
      <OrgHeader />
      <div className="beacon-details pt-2">
        <Row className="header justify-content-center flex-wrap">
          <Col lg={6}>
            <h1 className="pb-0 mb-0">Community Request Details</h1>
          </Col>
          <Col lg={6}>
            <div className="button-wrapper text-end">
              {beaconStatus === "COLLABORATION_COMPLETED" ? (
                "Collaboration Completed"
              ) : (
                <div className="d-flex align-items-center justify-content-end mb-3">
                  {isCreator(beaconData, auth?.user) && (
                    <Link
                      to="#"
                      variant="danger"
                      className="me-3 fs-6 text-black"
                      onClick={() =>
                        setShowCancelBeaconOrCollaborationModal(true)
                      }>
                      Cancel Collaboration/Request
                    </Link>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={6} md={12}>
            <strong className="beacon-id">
              Community Request ID: <span>{beaconData.slug}</span>
            </strong>
            <strong className="campaign-name">
              Campaign Name: <span>{beaconData.title} </span>
            </strong>
            <BeaconActionButton
              beaconData={beaconData}
              setShowReleaseAssetsModal={setShowReleaseAssetsModal}
              setShowAcceptAssetsModal={setShowAcceptAssetsModal}
              setShowCompleteBeaconModal={setShowCompleteCollaborationModal}
              setShowRejectAssetsModal={setShowRejectAssetsModal}
            />
            <Row className="mt-4 h-auto">
              <Col>
                <>
                  <div className="d-flex justify-content-between">
                    <Image
                      src={getAssetPublicUrl(beaconData.creator.logo)}
                      style={{ marginRight: 10 }}
                      className="logo"
                    />
                    <Button
                      className="chat-btn mx-auto"
                      onClick={() => {
                        window.scrollTo(0, document.body.scrollHeight);
                      }}>
                      <Image src={chatBubbles} />
                    </Button>
                  </div>
                  <dt>Creator</dt>
                  <dd>
                    {beaconData.creator.name}
                    <br />
                    ID: {beaconData.creator.id}
                  </dd>
                  <dt>Pick Up Address</dt>
                  <dd>
                    {beaconData.pickup_complete_address ?? "Not provided"}
                  </dd>
                  <dt>Phone</dt>
                  <dd>{beaconData.creator?.phone ?? "Not provided"}</dd>
                  <dt>Date</dt>
                  <dd>{beaconData.created_at_date}</dd>
                  <dt>Asset Type</dt>
                  <dd>{asset?.category?.name ?? "Not provided"}</dd>
                  <dt>Pieces</dt>
                  <dd>{asset?.quantity ?? "Not provided"}</dd>
                  <dt>Items</dt>
                  <dd>{asset?.item ?? "Not provided"}</dd>
                </>
              </Col>
              <Col>
                <>
                  <Image
                    src={getAssetPublicUrl(beaconData.collaborator.logo)}
                    className="logo"
                  />
                  <dt>Collaborator</dt>
                  <dd>
                    {beaconData.collaborator.name}
                    <br />
                    ID: {beaconData.collaborator.id}
                  </dd>
                  <dt>Drop Off Address</dt>
                  <dd>
                    {beaconData.dropoff_complete_address ?? "Not Provided"}
                  </dd>
                  <dt>Phone</dt>
                  <dd>{beaconData.creator?.phone ?? "Not Provided"}</dd>
                  <dt>Time</dt>
                  <dd>{beaconData.created_at_time}</dd>
                  <dt>Weight</dt>
                  <dd>{asset?.weight ?? "Not Provided"}</dd>
                </>
              </Col>
            </Row>
            <Row className="h-auto">
              <Col>
                <dt className="mb-2">Instructions</dt>
                <dd>
                  <p>
                    {beaconData.instructions ?? "No instructions provided."}
                  </p>
                </dd>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={12}>
            <div className="d-flex flex-column">
              <div>
                <h4 className="mb-3">Progress</h4>
                <StepsProgressBar
                  currentStep={currentStep}
                  beaconStatus={beaconData?.status}
                />
              </div>
              <Tabs defaultActiveKey="assetImages">
                <Tab eventKey="assetImages" title="Asset Images">
                  <div className="d-flex align-items-start flex-column">
                    {assetImages && <BeaconAssetImages images={assetImages} />}
                  </div>
                </Tab>
                <Tab eventKey="map" title="Map">
                  <MapContainer
                    pickup={{
                      address: beaconData.pickup_complete_address,
                      coordinates: beaconData.pickup_coordinates,
                    }}
                    dropoff={{
                      address: beaconData.dropoff_complete_address,
                      coordinates: beaconData.dropoff_coordinates,
                    }}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ChatPanel
              currentChannel={`${beaconData.slug}`}
              isPrivate={true}
              members={[
                beaconData?.creator?.slug,
                beaconData?.collaborator?.slug,
              ]}
            />
          </Col>
        </Row>
        <ReleaseAssetsModal
          show={showReleaseAssetsModal}
          setShow={setShowReleaseAssetsModal}
          onConfirm={releaseAssets}
        />
        <AcceptAssetsModal
          show={showAcceptAssetsModal}
          setShow={setShowAcceptAssetsModal}
          onConfirm={acceptAssets}
        />
        <CompleteCollaborationModal
          show={showCompleteCollaborationModal}
          setShow={setShowCompleteCollaborationModal}
          onConfirm={completeBeacon}
        />
        <RejectAssetsModal
          show={showRejectAssetsModal}
          setShow={setShowRejectAssetsModal}
          onConfirm={rejectAssets}
          onSecondaryAction={() =>
            navigate(`/beacons/${beaconData.slug}/incident-reports/new`)
          }
        />
        <CancelBeaconOrCollaboration
          show={showCancelBeaconOrCollaborationModal}
          setShow={setShowRejectAssetsModal}
          onConfirm={cancelCollaboration}
          onSecondaryAction={cancelBeacon}
        />
      </div>
    </>
  );
}

export default BeaconDetails;
